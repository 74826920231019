exports.components = {
  "component---src-components-blog-post-tsx-content-file-path-blog-gen-ai-applied-to-data-categorization-in-accounting-index-fr-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/genAI-applied-to-data-categorization-in-accounting/index.fr.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-gen-ai-applied-to-data-categorization-in-accounting-index-fr-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-gen-ai-applied-to-data-categorization-in-accounting-index-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/genAI-applied-to-data-categorization-in-accounting/index.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-gen-ai-applied-to-data-categorization-in-accounting-index-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-hugo-matthaey-shares-insights-on-ai-innovation-at-bernard-magrez-cultural-institute-index-fr-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/Hugo-Matthaey-Shares-Insights-on-AI-Innovation-at-Bernard-Magrez-Cultural-Institute/index.fr.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-hugo-matthaey-shares-insights-on-ai-innovation-at-bernard-magrez-cultural-institute-index-fr-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-hugo-matthaey-shares-insights-on-ai-innovation-at-bernard-magrez-cultural-institute-index-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/Hugo-Matthaey-Shares-Insights-on-AI-Innovation-at-Bernard-Magrez-Cultural-Institute/index.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-hugo-matthaey-shares-insights-on-ai-innovation-at-bernard-magrez-cultural-institute-index-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-reflektlab-to-showcase-innovations-at-start-up-day-2024-index-fr-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/REFLEKTLAB-to-Showcase-Innovations-at-Start-up-Day-2024/index.fr.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-reflektlab-to-showcase-innovations-at-start-up-day-2024-index-fr-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-reflektlab-to-showcase-innovations-at-start-up-day-2024-index-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/REFLEKTLAB-to-Showcase-Innovations-at-Start-up-Day-2024/index.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-reflektlab-to-showcase-innovations-at-start-up-day-2024-index-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-reflektlabs-success-at-start-up-day-2024-ai-in-the-spotlight-for-cfos-index-fr-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/reflektlabs-success-at-start-up-day-2024-ai-in-the-spotlight-for-cfos/index.fr.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-reflektlabs-success-at-start-up-day-2024-ai-in-the-spotlight-for-cfos-index-fr-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-reflektlabs-success-at-start-up-day-2024-ai-in-the-spotlight-for-cfos-index-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/reflektlabs-success-at-start-up-day-2024-ai-in-the-spotlight-for-cfos/index.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-reflektlabs-success-at-start-up-day-2024-ai-in-the-spotlight-for-cfos-index-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-the-future-of-accounting-automation-without-vast-data-index-fr-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/The-Future-of-Accounting-Automation-Without-Vast-Data/index.fr.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-the-future-of-accounting-automation-without-vast-data-index-fr-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-the-future-of-accounting-automation-without-vast-data-index-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/The-Future-of-Accounting-Automation-Without-Vast-Data/index.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-the-future-of-accounting-automation-without-vast-data-index-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-the-threat-of-the-gen-ai-revolution-index-fr-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/The-threat-of-the-GenAI-Revolution/index.fr.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-the-threat-of-the-gen-ai-revolution-index-fr-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-the-threat-of-the-gen-ai-revolution-index-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/The-threat-of-the-GenAI-Revolution/index.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-the-threat-of-the-gen-ai-revolution-index-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-unlocking-the-power-of-gen-ai-for-efficient-customer-review-analysis-index-fr-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/Unlocking-the-power-of-GenAI-for-efficient-customer-review-analysis/index.fr.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-unlocking-the-power-of-gen-ai-for-efficient-customer-review-analysis-index-fr-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-unlocking-the-power-of-gen-ai-for-efficient-customer-review-analysis-index-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/Unlocking-the-power-of-GenAI-for-efficient-customer-review-analysis/index.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-unlocking-the-power-of-gen-ai-for-efficient-customer-review-analysis-index-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-what-can-you-do-when-genai-too-hyped-index-fr-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/what-can-you-do-when-genai-too-hyped/index.fr.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-what-can-you-do-when-genai-too-hyped-index-fr-mdx" */),
  "component---src-components-blog-post-tsx-content-file-path-blog-what-can-you-do-when-genai-too-hyped-index-mdx": () => import("./../../../src/components/BlogPost.tsx?__contentFilePath=/opt/buildhome/repo/blog/what-can-you-do-when-genai-too-hyped/index.mdx" /* webpackChunkName: "component---src-components-blog-post-tsx-content-file-path-blog-what-can-you-do-when-genai-too-hyped-index-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-advanced-document-query-tsx": () => import("./../../../src/pages/Advanced-Document-Query.tsx" /* webpackChunkName: "component---src-pages-advanced-document-query-tsx" */),
  "component---src-pages-ai-for-accounting-tsx": () => import("./../../../src/pages/AI-for-Accounting.tsx" /* webpackChunkName: "component---src-pages-ai-for-accounting-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-chat-tsx": () => import("./../../../src/pages/chat.tsx" /* webpackChunkName: "component---src-pages-chat-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contract-tsx": () => import("./../../../src/pages/contract.tsx" /* webpackChunkName: "component---src-pages-contract-tsx" */),
  "component---src-pages-crm-tsx": () => import("./../../../src/pages/crm.tsx" /* webpackChunkName: "component---src-pages-crm-tsx" */),
  "component---src-pages-demo-tsx": () => import("./../../../src/pages/demo.tsx" /* webpackChunkName: "component---src-pages-demo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leads-tsx": () => import("./../../../src/pages/leads.tsx" /* webpackChunkName: "component---src-pages-leads-tsx" */),
  "component---src-pages-recruitment-tsx": () => import("./../../../src/pages/recruitment.tsx" /* webpackChunkName: "component---src-pages-recruitment-tsx" */),
  "component---src-pages-technology-tsx": () => import("./../../../src/pages/technology.tsx" /* webpackChunkName: "component---src-pages-technology-tsx" */),
  "component---src-pages-voice-to-crm-tsx": () => import("./../../../src/pages/Voice-to-CRM.tsx" /* webpackChunkName: "component---src-pages-voice-to-crm-tsx" */)
}

